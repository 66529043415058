<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe pagination :max-items="20" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import { db, FieldValue } from '@/firebase/firebaseConfig'

import ubigeo from '../../data/ubigeo/ubigeo.json'

export default {
  name: 'ImportProducts',
  components: {
    ImportExcel
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      ubigeo: ubigeo
    }
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async importData () {
      try {
        this.initProgress = true
        // Write products
        let batch = db.batch()
        let count = 0
        const batchs = []
        batchs.push(batch)
        let flag = 0
        for await (let data of this.tableData) {
          count++
          let obj = {
            businessName: data.razonSocial,
            typeDocument: data.tipoDocumento,
            document: data.nroDocumento,
            address: data.direccion,
            department: data.nomDepartamento === 'NULL' ? {} : data.nomDepartamento,
            province: data.nomProvincia === 'NULL' ? {} : data.nomProvincia,
            district: data.nomDistrito === 'NULL' ? {} : data.nomDistrito,
            email: data.correoElectronico,
            phone: data.telefono,
            state: true,
            ubigeo: '',
            createdAt: FieldValue.serverTimestamp(),
            mysqlId: data.idProveedor
          }
          await this.getInfoOfDocumentSunat(obj)
          console.log(obj)
          const productRef = db.collection('providers').doc()
          if (count < 249) {
            batchs[flag].set(productRef, {
              ...obj
            })
          } else {
            flag++
            count = 0
            batchs.push(db.batch())
          }
        }
        // Save in firestore
        for await (let b of batchs) {
          await b.commit()
        }
        // End
        this.$vs.notify({
          color: 'success',
          title: 'Importacion',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Get info of document Sunat
     */
    async getInfoOfDocumentSunat (provider) {
      try {
        if (provider.typeDocument && provider.document) {
          const response = await this.$http.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/vendor-getInfoOfDocumentSunat?type=' + provider.typeDocument + '&document=' + provider.document, {
            headers: {
              Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
            }
          })
          if (response.data) {
            await this.$validator.reset()
            provider.businessName = response.data.nombre
            provider.address = response.data.direccion
            provider.department = response.data.departamento
            provider.province = response.data.provincia
            provider.district = response.data.distrito
            provider.ubigeo = response.data.ubigeo
          }
        } else {
          this.$vs.notify({
            color: 'warning',
            title: 'Error',
            text: 'Selecciona tipo de documento e ingresa el número.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    }
  }
}
</script>
